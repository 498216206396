import pitaShot from '../assets/pitaShot.webp'
import hold from '../assets/hold.webp'
import hold2 from '../assets/hold2.webp'
import cateringpic from '../assets/cateringpic.webp'
import cateringpic2 from '../assets/cateringpic2.webp'
import fourpita from '../assets/fourpita.webp'
import snap from '../assets/snap.webp'
import burger from '../assets/burger.webp'
import fry from '../assets/fry.webp'
import ez from '../assets/ez.webp'
import logo from '../assets/logo.webp'
import zero from '../assets/zero.webp'
import cow from '../assets/cow.webp'
import menu from '../assets/gpm.jpg'
const images = {
    pitaShot,
    hold,
    hold2,
    cateringpic,
    cateringpic2,
    fourpita,
    ez,
    fry,
    burger,
    snap,
    logo,
    zero,
    cow,
    menu
};

export default images;