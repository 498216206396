import React from 'react';
import { Link } from 'react-router-dom';
import images from './constants/images';

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-white">
            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center">
                    <div className="text-center md:text-left">
                        <h3 className="text-xl font-bold mb-4">Contact Us</h3>
                        <p>Chicago, Illinois (60638)</p>
                        <p>Phone: +1(630)-677-4147</p>
                        <p>Email: lilgreekkitchen@gmail.com</p>
                    </div>
                    <div className="flex justify-center items-center">
                        <img src={images.logo} loading='lazy' alt="Lil' Greek Kitchen Logo" className="h-24 rounded w-auto" />
                    </div>
                    <div className="text-center md:text-right">
                        <h3 className="text-xl font-bold mb-4">Catering Hours</h3>
                        <p>Monday - Sunday: 11am - 10pm</p>
                    </div>
                </div>
                <div className="mt-8 border-t border-gray-800 pt-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ul className="flex justify-center md:justify-start space-x-4">
                            <li><Link to="/" className="hover:text-blue-400 transition duration-300">Home</Link></li>
                            <li><Link to="/catering" className="hover:text-blue-400 transition duration-300">Catering</Link></li>
                            <li><Link to="/events" className="hover:text-blue-400 transition duration-300">Events</Link></li>
                            <li><Link to="/about" className="hover:text-blue-400 transition duration-300">About Us</Link></li>
                            <li><Link to="/contact" className="hover:text-blue-400 transition duration-300">Contact</Link></li>
                        </ul>
                        <p className="text-center md:text-right">&copy; 2024 Lil' Greek Kitchen. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;