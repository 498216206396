import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <header className="bg-gray-900 text-white">
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                <div>
                    <Link to="/" className="text-2xl font-bold">Lil' Greek Kitchen</Link>
                    <span className="block text-sm text-gray-400">Chicago Greek Cuisine</span> {/* Subtitle */}
                </div>
                <nav className="hidden md:flex space-x-4">
                    <Link to="/" className="hover:text-blue-400 transition duration-300">Home</Link>
                    <Link to="/catering" className="hover:text-blue-400 transition duration-300">Catering</Link>
                    <Link to="/events" className="hover:text-blue-400 transition duration-300">Events</Link>
                    <Link to="/about" className="hover:text-blue-400 transition duration-300">About</Link>
                    <Link to="/contact" className="hover:text-blue-400 transition duration-300">Contact</Link>
                </nav>
                <button
                    className="md:hidden focus:outline-none"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>
            {isMenuOpen && (
                <nav className="md:hidden bg-gray-800 px-4 py-2">
                    <Link to="/" className="block py-2 hover:text-blue-400 transition duration-300">Home</Link>
                    <Link to="/catering" className="block py-2 hover:text-blue-400 transition duration-300">Catering</Link>
                    <Link to="/events" className="hover:text-blue-400 transition duration-300">Events</Link>
                    <Link to="/about" className="block py-2 hover:text-blue-400 transition duration-300">About</Link>
                    <Link to="/contact" className="block py-2 hover:text-blue-400 transition duration-300">Contact</Link>
                </nav>
            )}
        </header>
    );
};

export default Header;